import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueJwtDecode from 'vue-jwt-decode'


import Login       from '@/views/Login/Login.vue'
import Olvidacontra from '@/views/Login/Olvidacontra'

import store from '../store'

Vue.use(VueRouter)
Vue.use(VueJwtDecode)

const routes = [
  
  { path: '/',  name: 'login2', component: Login , meta: { libre: true }},  
  { path: '/login',  name: 'Login', component: Login, meta: { libre: true }},
  

  { path: '/olvidacontra',  name: 'olvidacontra', component: Olvidacontra},
  // { path: '/home',  name: 'home', component: Home, meta: { requiresAuth: true }},

  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { libre: true }
  },


  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { libre: true }
  },
  {
    path: '/condocs',
    name: 'condocs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "condocs" */ '../views/inventarios/Condocs.vue'),
     meta: { libre: true }
  },
  {
    path: '/coninv',
    name: 'coninv',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coninv" */ '../views/inventarios/Coninv.vue'),
     meta: { libre: true }
  },

  {
    path: '/kardex',
    name: 'kardex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kardex" */ '../views/inventarios/kardex.vue'),
     meta: { libre: true }
  },
  {
    path: '/leearticulo',
    name: 'leearticulo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "leeArts" */ '../components/leearticulo.vue'),
     meta: { libre: true }
  },



  {
    path: '/documento',
    name: 'documento',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "documento" */ '../views/inventarios/Documento.vue'),
     meta: { libre: true }
  },
  { path: '/impresion',   name: 'impresion',   component: ()=>  import('@/views/inventarios/Impresion.vue') ,
      meta: { ADMIN: true } },




]

const router = new VueRouter({
  routes, 
  mode: 'history',
  base: process.env.BASE_URL
})

export default router

router.beforeEach( (to, from, next) => {

  if (localStorage.getItem('tlakey')) {
      // var cToken  = localStorage.soporte;
      next()
      // NO TIENE TOKEN. cHORIZO
  }

  if( to.matched.some(record => record.meta.libre)){
    // console.log('La ruta es libre => pasa')
    next()

  } else if(store.state.usuario && store.state.nivel == "ADMIN"){
    // console.log('El usuario es Nivel admin', store.state.usuario)

    if(to.matched.some(record => record.meta.ADMIN)){
        
      next()
    }

  } else{
   
    //No hay nivel => Mando a Login
    next({
      name: 'Login'
    })
  }
})
