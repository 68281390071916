<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="getLogeado == true" 
      v-model="drawer"
      app
      >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Bienvenido
          </v-list-item-title>
          <v-list-item-subtitle>
            Consulta de Inventarios 
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to ="item.to"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="#fcb69f"
      dark
      src="@/assets/embarques-banner.jpg"
      
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>SAIT EMBARQUES</v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>


    <v-main>
      <router-view></router-view>
      <snackbar />
      
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
  export default {
    data: () => ({ 
      drawer: null,  
      items: [
          { title: 'Home', icon: 'mdi-apps-box', to: '/Home' },
          { title: 'Kardex', icon: 'mdi-apps-box', to: '/kardex' },
          
          { title: 'Consulta de inventarios', icon: 'mdi-newspaper-variant-outline', to:'/coninv'} ,
          { title: 'Consulta de Movimientos', icon: 'mdi-newspaper-plus', to: '/condocs' },
          { title: 'Salir', icon: 'mdi-exit-to-app', to: '/' },           
       ],
    }),
    components: {
      'snackbar' : require('@/components/Shared/Snackbar.vue').default
    },

    computed:{
     ...mapGetters('Login', ['getdatosUsuario', 'getLogeado']),
    },

    methods: {
       salir(){
        this.$store.dispatch("salir")
        this.salirLogin()
      }
    }
  }
</script>