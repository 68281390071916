import Vue from 'vue'
// import Vuex from 'vuex'
// import axios from 'axios'
import router from '@/router'

export default{
	namespaced: true,
	state:{
		direcciones: [],
    direccion:[],
	},

	mutations:{
		DIRECCIONES(state, value){
      state.direcciones = value
		},

    VERDIRECCION(state, direccion){
      state.direccion = direccion
    },


	},

	actions:{
		traerDirecciones({commit}, numcli){
			return new Promise((resolve) => {

				Vue.http.get('auth/api/v1/succlibynumcli/' + numcli).then(response=>{
          // console.log("traerDirecciones js", response)
          if(typeof(response.body) == 'object'){
            commit('DIRECCIONES', response.body)
            resolve(true)
          }else{
            resolve(false)
          }
        }).catch(function(error){
        
           if(error.status == 500){
            resolve(false)
        
          }
        })
			})
		},

    verDireccion({commit}, idweb){
      return new Promise((resolve) => {
        Vue.http.get('auth/api/v1/succli/' + idweb).then(response=>{
          commit('VERDIRECCION',response.body)
          resolve(true)
        }).catch(function(error){
          console.log('error', error.body)
        })
      })
    },

    actualizarDireccion( payload){
      return new Promise((resolve) => {
        Vue.http.put('auth/api/v1/succcli.update/'+ payload.idweb ,payload).then(response=>{
          if(response.status == 500){
            resolve(false)
          }else{
            resolve(true)
          }
        }).catch(function(error){
          if(error.status == 500){
            resolve(false)
          }else{
            router.push({name:'envios'})
          }
          // console.log(error)
          // console.log('error', error.body)
        })
      })
    },

    addDireccion( payload){
      return new Promise((resolve) => {
        Vue.http.post('auth/api/v1/succcli.add',payload).then(response=>{
          if(response.status == 500){
            resolve(false)
          }else{
            resolve(true)
          }
        }).catch(function(error){
          if(error.status == 500){
            console.log(error.body)
            resolve(false)
          }
        })
      })
    },

    deleteDireccion( payload){
      return new Promise((resolve) => {
        Vue.http.delete('auth/api/v1/succli/'+payload).then(response=>{
          if(response.status == 200){
            resolve(true)
          }else{
            resolve(false)
          }

        }).catch(function(error){
          console.log(error.status)
          console.log('error', error.body)
        })
      })
    },

  },

	getters:{
		getDirecciones(state){
      // console.log("getters getDirecciones")
			return state.direcciones
		},

		getDireccion(state){
			return state.direccion
		},

	}
}