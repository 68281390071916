import Vue from 'vue'
// import Vuex from 'vuex'

export default{
	namespaced: true,

	state:{
		login:false,
		datosUsuario:'',
		idusuariosweb: '',
		clientes:[],
		cliente:'',
		vendedor:'',
		tipousuario:'',
		cia:'',
	},

	mutations:{
		LOGEADO(state, value){
			state.login = value
		},
		DATOS_USUARIO(state, datosUsuario){
      state.datosUsuario = datosUsuario
		},
		ID_USUARIO(state, idusuariosweb){
			state.idusuariosweb = idusuariosweb
		},

		CLIENTES_USER(state, clientes){
			state.clientes = clientes
		},

		UPDATE_CLIENTE(state, cliente){
			state.cliente = cliente
		},

		INFOVEN(state, infovend){
			state.vendedor = infovend
		},

		TIPOUSUARIO(state, tipousuario){
			state.tipousuario = tipousuario
		},

		EMPRESA(state, cia){
			state.cia = cia
		},

		SALIR(state){
			state.login = false
			state.datosUsuario = ''
			state.idusuariosweb =  ''
			state.clientes = []
			state.cliente = ''
			state.vendedor = ''
			state.tipousuario= ''
		}
	},

actions:{
	// Valida si el usario existe en la BD
	validarUser({commit}, payload){
		return new Promise((resolve, reject) => {
				console.log (payload)
				commit('LOGEADO', false)
			Vue.http.post('login', payload).then(respuesta=>{
				return respuesta.json()
				}).then(respuestaJson=>{
				// console.log('respuestaJson',respuestaJson)
				if (respuestaJson == null){
				resolve(false) 
				} else {
				resolve(respuestaJson) 
				}
			}, error => {
			reject(error)
			})
		})
	},

	GetInfoUser({commit, dispatch}, usuario){
		return new Promise((resolve, reject) => {
			console.log("buscarusuario", usuario)

			Vue.http.post('buscarusuario', usuario).then(response=>{
				console.log('responseJsonEMAIL', response)
				if(response == null){
					resolve(false) 
				}else{

				// console.log('response buscarusuario',response.body)
				if (response.body.result.nivel =="ADMIN"){
					commit('LOGEADO', true)
					commit('DATOS_USUARIO',response.body.result)
					resolve(response.body) 
					// var cNumcli = response.body.numcli.trim()
					dispatch('infoCIA')
				}
				// PARA QUE regrese que al login.
				resolve(true)
				}
			}, error => {
					reject(error)
				})
			})
	},

	traerCientes({commit}, numcli){
		Vue.http.get('auth/api/v1/clientebynumcli/' + numcli).then(respuesta=>{
		var arr = []
		if(typeof(respuesta) == 'object'){
		arr.push(respuesta.body)
		commit('CLIENTES_USER',arr)
		commit('TIPOUSUARIO', 'cliente')
		}
		}, error => {
		console.log(error)
		})
		},

		infoCIA({commit}){
			Vue.http.get('cialist').then(respuesta=>{
				commit('EMPRESA', respuesta.body)
				}, error => {
				console.log(error)
			})
		},


		updateCliente({commit}, cliente){
			commit('UPDATE_CLIENTE', cliente)
		},

		infovendedor({commit}, idvend){
			Vue.http.get('auth/api/v1/vend/' + idvend).then(response=>{
				// console.log(response.body)
				commit('INFOVEN', response.body)
				commit('TIPOUSUARIO', 'vendedor')
			})
		},

		salirLogin({commit}){
			commit('SALIR')
		},
	},

	getters:{
		getLogeado(state){
			return state.login
		},
		getdatosUsuario(state){
			return state.datosUsuario
		},

		getidUsuariosWeb(state){
			return state.idusuariosweb
		},

		getClientes(state){
			return state.clientes
		},

		// getCliente(state){
		// 	return state.clientes[0]
		// },

		getCliente(state){
			return state.cliente
		},

		getInfoVend(state){
			return state.vendedor
		},

		getModo(state){
			return state.tipousuario
		},

		getCIA(state){
			return state.cia
		}

	}
}