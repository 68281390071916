import Vue from 'vue'
// import Vuex from 'vuex'
// import router from '@/router'

export default{
	namespaced: true,
	state:{
		productos:[],
		idimg:'',
	},

	mutations:{
		PRODUCTOS(state, value){
			state.productos = value
		},
	},

	actions:{
		//Función para cargar los avisos de
		traerProductos({commit}){
			//retornar una promesa (resolve, rejecet)
			return new Promise((resolve, reject) => {
				Vue.http.get('api/v1/arts.list')
					.then(respuesta=>{return respuesta.json()})
					.then(respuestaJson=>{
					// console.log('Productos', respuestaJson)
					if(respuestaJson == null){
					resolve(null) 
					}else{
					commit('PRODUCTOS',respuestaJson)
					resolve(true) 
					}
				}, error => {
					reject(error)
				})
      })
		},		
  },

	getters:{
		getProductos(state){
			return state.productos
		},
	}
}