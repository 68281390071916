
import Vue from 'vue'
import Vuex from 'vuex'
import VueJwtDecode from 'vue-jwt-decode'

import router from '@/router'

import Login     from '@/modules/Login/Login'
import Registro  from '@/modules/Login/Registro'	
import productos from '@/modules/productos'
import documento from '@/modules/documento'	

import leecliente from '@/modules/leeclaves/leecliente'
import leevendedor from '@/modules/leeclaves/leevendedor'
import leeart from '@/modules/leeclaves/leeart'

import direccion from '@/modules/Perfil/direccion'  
import snackbar from '@/modules/snackbar'



Vue.use(Vuex,VueJwtDecode)


export default new Vuex.Store({
  state: {
    token: null,   
    usuario: null,
    nivel: null,
    drawer: true,
    menu: true,
    logotienda:''
  },

  mutations: {
    setToken(state, token){
      state.token= token
    },
    setUsuario (state, usuario){
      state.usuario= usuario
    },
    setNivel (state, nivel){
      state.nivel=  nivel
    }
  },
  actions: {
    guardarToken({commit},token){
      commit("setToken", token)
      localStorage.setItem("tlaKey", token)

      //Decifrar Usuario
      var decode        = VueJwtDecode.decode(token)
      var UsuarioActivo = decode.id 
      commit("setUsuario", UsuarioActivo)   // Decodifica el token para sacar usuario
    },

    guardarNivel({commit},nivel){
    // console.log("gaurdarnivel", nivel)
    commit('setNivel', nivel)

    },

    salir({commit}){
      // localStorage.removeItem("token")
      commit("setUsuario", '')
      commit("setToken", '')
      localStorage.removeItem("tlaKey")
        //var tokenGen = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NjIyNTg4NjgsImlkIjoiYWRtaW4iLCJvcmlnX2lhdCI6MTU2MjI1NTI2OH0.SWGD5aH16otmX_cu-EaQ9OlgnLwV-WxbAuy85LUS1bE';

      //localStorage.capaKey  = JSON.stringify(tokenGen);
      router.push({name: 'Login'}).catch(error => {console.log(error)})
    }
  },

  getters:{
		traeNomuser(state){
			// console.log('traeNomuser')
			return state.usuario
		},
		traeNivel(state){
			// console.log('traeNivel')
			return state.nivel
		},

	},

	modules:{
		Login,
		Registro,
		productos,
		documento,
		leecliente,
		leevendedor,
    leeart,
    direccion,
    snackbar
	}

})

