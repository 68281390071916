import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: colors.green.darken4, 
        secondary: colors.black, 
        accent: colors.indigo.base,  
        error:  colors.red.darken1,
        info:   colors.orange,
		success: colors.green.darken2,
		warning :  colors.teal
      },
    },
  },
});
