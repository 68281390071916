// import Vue from 'vue'
// import Vuex from 'vuex'
// import router from '@/router'

export default{
	namespaced: true,
		// EL OBJETO LEE CLIENTE DEBE TENER SOLO
		// { numcli: '12929',
		// 	 nomcli: 'COMERCIAL FESTIVA',
		// 	 idweb:  232, }

	state:{
		leecliente:{}
	},

	mutations:{
		SET_LEE_CLIENTE(state, leecliente){
      state.leecliente = leecliente
		},
	},

	actions:{
    setleecliente({commit}, leecliente){
      commit('SET_LEE_CLIENTE',leecliente)	
    }
	},

	getters:{
		getleecliente(state){
      return state.leecliente
		},		
	}
}