<template>

  <div class="home">
	<v-card >
		<v-img max-height= "200" alt="Vue logo" src="../assets/logo-embarques.png" contain>  			
		</v-img>

		<v-img max-height= "200" alt="logo empresa" :src="logourl" contain>  			
		</v-img>
	</v-card>
  </div>



</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',

  data () {
      return {
        //FECHAS
        date1: new Date().toISOString().substr(0, 10),
        logourl:'',
       }
   },
  created(){
	
    this.$http.get('get.logo').then(response=>{
      // console.log("response", response)
      if (response.data.error == null){
        this.logourl = response.data.result.logourl
        // console.log("logo", this.logourl)
      }
      
    }, error => {
      console.log(error)
    })
  },

}

</script>
