// import Vue from 'vue'
// import Vuex from 'vuex'
// import router from '@/router'

export default{
	namespaced: true,

	state:{
		leeart:{
            numart:'',
            descrip:''
          }
	},

	mutations:{
		SET_LEE_ART(state, leeart){
            state.leeart = leeart
		},
	},

    actions:{
        setleeart({commit}, leeart){
            commit('SET_LEE_ART',leeart)	
        }
    },

	getters:{
		getleeart(state){
      return state.leeart
		},		
	}
}