<template>
  <v-container>
    <v-row class=" mt-4 justify-center ">
      <v-col cols="12" md="5">
        <v-snackbar
          v-model="snackbar"
          :timeout="8000"
          top
          color="orange" dark
        >
          {{text}}
          <v-btn
            color="white"
            text
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </v-snackbar>

      <v-card>
        <v-flex xs12>
          <v-toolbar color="primary" dark>
            <v-toolbar-title > Recuperar contraseña </v-toolbar-title>
            
          </v-toolbar>
        </v-flex>
      </v-card>

      <v-card class="mt-1 pa-3" align="center">
        <div class="logo "  >
          <img :src="logo" width="250" height="80%" class="mb-5" >
        </div>
            <!-- EMAIL -->
        <v-flex xs12>
          <v-text-field
            solo
            
            v-model="email" 
            append-icon="email" 
            label="Correo" 
            required
            type="text">
          </v-text-field>
        </v-flex>
        
        <v-divider></v-divider>
        
        <v-card-actions class="mt-1 mx-3">
            <v-spacer></v-spacer>
              <v-btn small dark color="grey lighten" :to="{name:'login'}">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="red darken-1" dark @click="ValidarCorreo" class="elevation-5">Recordar</v-btn>
            
        </v-card-actions>
      </v-card>
        

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data(){
      return{
        email:'',
        id   : '',
        Nomuser: '',
        snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 8000,
        text: '',
        logo:'',
      }
    },

    created(){
      this.$http.get('api/v1/logos.list').then(response=>{
        this.logo = response.body.Logourl
      }, error => {
        console.log(error)
      })
    },

    computed:{
      // ...mapGetters('Login',['getdatosUsuario']),
    },

    methods:{
      Recuperar (){
        if(this.email == ''){
          this.snackbar = true ; this.text = "Capture su email"
          return
        }

        const payload= {  
          Email: this.email,
          logo: this.logo 
        }

        // console.log("payload", payload)
        this.$http.post('api/v1/olvidacontra', payload).then(() =>{
          // console.log("response", response)
        }).catch(error=>{
          console.log(error)       
        }) 

      },


    ValidarCorreo () {
      if(this.email == ''){
        this.snackbar = true ; this.text = "Capture su correo electronico"
        return
      }
      const payload= {  Email: this.email }

      return new Promise((resolve) => {

      this.$http.post('api/v1/olvidacontra', payload) .then(respuesta=>{
        return respuesta.json()
        // console.log( "olvidacontra", respuesta)
      }).then(respuestaJson=>{

        if(respuestaJson === null){
        resolve(false)
        this.snackbar= true ; this.text="Esté correo no es valido." ; this.timeout=3000
        }else{

        // console.log(respuestaJson)
        var id = respuestaJson[0].idusuariosweb

        const urllink = process.env.VUE_APP_OPTIONS_ROOT

        var curl = urllink.replace("/tienda3", "");

        // console.log(curl + "/cambiacontra/" + id)

        const payload = {
          Idusuariosweb  : respuestaJson[0].idusuariosweb,
          Nomuser : respuestaJson[0].Nomuser,
          Email  : respuestaJson[0].Email,
          logo : this.logo,
          color : this.$vuetify.theme.themes.light.primary,
          ruta  : curl + "/cambiacontra/" + id
        }

        // console.log(payload)

        this.Enviarcorreo(payload)
        resolve(true)
        }

        }).catch(function(error){console.log('error',error)})
        })

    },

  Enviarcorreo (payload){

  this.$http.post('api/v1/sendolvidacontra',payload).then(function(response){
  if(response.status == 200){

  this.color = 'success'
  this.snackbar = true
  this.text = 'Se ha enviado el correo de validacion a su cuenta.'
  this.to=''
  this.subject=''
  this.cuerpo=''
  }else{
  this.color = 'red'
  this.snackbar = true
  this.text = 'Falla al enviar el correo'
  this.to=''
  this.subject=''
  this.cuerpo=''

  }  

  var me = this
  // Mandar a login
  setTimeout(function(){ me.$router.push({name: 'login'}) }, 3000);
  })

  },
  }
};


</script>

<style scoped>
  
  .logo{
    align-content: "center";
    text-align: center;

  }
</style>
