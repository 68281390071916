// import Vue from 'vue'
// import Vuex from 'vuex'
// import router from '@/router'

export default{
	namespaced: true,
		// EL OBJETO LEE VEND DEBE TENER SOLO
		// { numvend: '    9',
		// 	 nomvend: 'Ing. Manuel Gutierrez',
		// 	 idvend:  3, }

	state:{
		leevendedor:{}
	},

	mutations:{
		SET_LEE_VENDEDOR(state, leevendedor){
			state.leevendedor = leevendedor
		},
	},

	actions:{
		setleevendedor({commit}, leevendedor){
      commit('SET_LEE_VENDEDOR',leevendedor)	
    }
	},

	getters:{
		getleevendedor(state){
      return state.leevendedor
		},		
	}
}