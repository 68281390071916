import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'

Vue.config.productionTip = false
Vue.use(VueResource)

if (process.env.NODE_ENV=="development"){
  // Vue.http.options.root = 'http://localhost:8099/tienda3'
 Vue.http.options.root = 'http://localhost:8094/api'
 // Vue.http.options.root = 'https://kam.saiterp.com/api/';
    
} else {
  // Vue.http.options.root = 'https://kan.saiterp.com/api'
  Vue.http.options.root = process.env.VUE_APP_OPTIONS_ROOT
  // Vue.http.options.root = 'https://kam.saiterp.com/api/';

}


  // Vue.http.options.root = 'https://kam.saiterp.com/api/'
  // Vue.http.options.root = 'http://localhost:8088/tienda3'  

  // Vue.http.options.root = 'http://localhost:8099/tienda3'  
  
// console.log("otption root ",Vue.http.options.root)


if (!localStorage.getItem('tlKey')) {
  localStorage.clear();
  var tokenGen = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NjIzMjkyMDUsImlkIjoibWFudWVsQHNhaXQubXgiLCJvcmlnX2lhdCI6MTU2MjMyNTYwNX0.-VEL2pk4ihCI9GTSMbye3AlzWjqCMUY4bMJqFmQZYVA';
  localStorage.tlKey  = JSON.stringify(tokenGen);
}



console.log(localStorage.tlKey)
Vue.http.interceptors.push((request, next) => {
  request.headers.set('Authorization', 'Bearer '+ localStorage.tlakey)
  request.headers.set('Accept', 'application/json')
  next()
});



// Vue.http.interceptors.push((request, next) => {
//    // var token = JSON.parse(localStorage.tlaKey)
//    // console.log (token)
//   request.headers.set('Authorization', 'Bearer '+ localStorage.tlaKey)
//   request.headers.set('Accept', 'application/json')
//   next()
// });


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
